import React from 'react'
import { Button } from '../components/Button'
import { Link } from 'react-router-dom'
import { HeroBlock } from '../components/HeroBlock'

export const NotFound = () => {
  return (
    <div className='page notFound'>
      <HeroBlock
        title="404"
        desc="Страница, которую вы запрашиваете, не существует или перемещена."
        btn={<Link to="/"><Button text="Вернуться"/></Link>}
        className="notFound"/>
    </div>
  )
}
