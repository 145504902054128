import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Loading } from './Loading'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export const Card = ({title, imgsrc, link}) => {
  const [img, setImg] = useState(imgsrc)
  const [loaded, setLoaded] = useState(false)

  function setErr() {
    setImg('/images/broken-img.svg')
  }
  if (img==undefined){
    setErr()
  }
  return (
    <Link to={link} className="default-card">
      <span className="default-card_title">{title}</span>
      <LazyLoadImage src={img} alt="" className={!loaded && 'loading'} onLoad={() => setLoaded(true)} onError={setErr}/>
      {!loaded && <Loading/>}
    </Link>
  )
}
