import React from 'react'

export const Footer = () => {
  return (
    <footer>
      <div className="site-container">
        <img src="/images/logo_vertical.svg" alt="" />
        <div className="footer-info">
          <span><a href="tel:+79778492010">+7 977 849 20 10</a></span>
          <div className="header-contacts">
              <a href="https://telegram.org" className='header-icon'>
                <img src="/icons/telegram.svg" draggable='false' alt="Telegram" />
              </a>
              <a href="https://instagram.com" className='header-icon'>
                <img src="/icons/instagram.svg" draggable='false' alt="Instagram" />
              </a>
              <a href="https://whatsapp.com" className='header-icon'>
                <img src="/icons/whatsapp.svg" draggable='false' alt="Whatsapp" />
              </a>
            </div>
        </div>
      </div>
    </footer>
  )
}
