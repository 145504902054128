import React, { useEffect, useState } from 'react'
import { HeroBlock } from '../components/HeroBlock'
import ImageGallery from 'react-image-gallery'
import { Link, useParams } from 'react-router-dom';
import Markdown from 'react-markdown';
import { Loading } from '../components/Loading';
import { supabase } from '../client';
import { NotFound } from './NotFound';


export const BlogPage = (props) => {
  const[item, setItem] = useState(undefined);
  const[itemImages, setItemImages] = useState();
  const[notFound, setNotFound] = useState(false)
  let location = useParams();

  async function getProjects(){
    let { data: projects, error } = await supabase
    .from('projects')
    .select("*")
    .eq('url', location.projectName)
    if(projects.length==0){
      setNotFound(true)
    } else {
      setItem(projects[0])
      setItemImages(projects[0].images.map(item => ({
        original: item
      })))
    }
  }
  async function getBlogs(){
    let { data: blogs, error } = await supabase
    .from('blogs')
    .select("*")
    .eq('url', location.blogName)
    if(blogs.length==0){
      console.log(error)
      setNotFound(true)
    } else {
      setItem(blogs[0])
      setItemImages(blogs[0].images!==null ? blogs[0].images.map(item => ({
        original: item
      })) : null)
    }
  }

  useEffect(() => {
    if(props.type == "project"){
      getProjects()
    }
    if(props.type == "blog"){
      getBlogs()
    }
  }, [])


  if(props.type == "project" && !notFound){
    return (
      <div className="page">
        {item!==undefined ? <HeroBlock title={item.title} desc={item.desc} thread="проекты" bg={item.main_img}/> : <Loading/>}
        {item!==undefined ? <div className="site-container">
          <div className="images-cards">
            {itemImages!==null && <ImageGallery items={item.images.map(item => ({original: item}))} lazyLoad={true} showBullets={true} showFullscreenButton={false} showPlayButton={false}/>}
            <Link className='images-cards_back' to="/">{"< Вернуться"}</Link>
          </div>
        </div> : ""}
      </div>
    )
  }
  if(props.type == "blog" && !notFound){
    return (
      <div className="page blog">
        {item!==undefined ? <HeroBlock title={item.title} desc={item.date} thread="блог" bg={item.main_img}/> : <Loading/>}
        {item!==undefined ? <div className="site-container">
          <div className="images-cards">
            <div className="blog-text">
              <Markdown>{item.text}</Markdown>
            </div>
            <br />
            <br />
            {itemImages!==null && <ImageGallery items={item.images.map(item => ({original: item}))} lazyLoad={true} showBullets={true} showFullscreenButton={false} showPlayButton={false}/>}
            <Link className='images-cards_back' to="/blog">{"< Вернуться"}</Link>
          </div>
        </div> : ""}
      </div>
    )
  }
  if(notFound){
    return(
      <NotFound/>
    )
  }
}
