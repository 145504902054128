import React from 'react'
import { HeroBlock } from '../components/HeroBlock'
import { Button } from '../components/Button'
import { Link } from 'react-router-dom'

export const Prices = () => {
  return (
    <div className='page'>
      <HeroBlock
        title="Тарифы"
        thread="тарифы"
        desc="Мы ценим наших клиентов, и поэтому контролируем все этапы проекта от начала до конца"
        bg="/images/hero_prices.jpg" />

      <div className="site-container prices-page">
        <div className="prices-page_plan">
          <div className="price-top">
            <h2>ЭКСПРЕСС</h2>
            <h3>Состав проекта:</h3>
            <ul>
              <li className='stage'>1 этап</li>
              <ul>
                <li>обмерный план</li>
                <li>планировочное решение</li>
                <li>план демонтажа</li>
                <li>план монтажа</li>
                <li>план сантехники</li>
              </ul>
              <li className='stage'>2 этап</li>
              <ul>
                <li>стилистический коллаж</li>
                <li className='space'></li>
              </ul>
              <li className='stage'>3 этап</li>
              <ul>
                <li>план розеток и выключателей</li>
                <li>план освещения</li>
                <li>план групп включений</li>
                <li>план теплых полов</li>
                <li>план полов</li>
                <li>план потолков</li>
                <li>план спецификации дверей</li>
                <li>Перечень отделочных материалов</li>
              </ul>
            </ul>
          </div>
          <div className="price-bottom">
            <div className="price">3900 руб. / кв. м</div>
            <Link to="/contact"><Button text="Заказать" /></Link>
          </div>
        </div>
        <div className="prices-page_plan">
          <div className="prices-top">
            <h2>КОМФОРТ</h2>
            <h3>Состав проекта:</h3>
            <ul>
              <li className='stage'>1 этап</li>
              <ul>
                <li>обмерный план</li>
                <li>планировочное решение</li>
                <li>план демонтажа</li>
                <li>план монтажа</li>
                <li>план сантехники</li>
              </ul>
              <li className='stage'>2 этап</li>
              <ul>
                <li>стилистический коллаж</li>
                <li className='space'></li>
              </ul>
              <li className='stage'>3 этап</li>
              <ul>
                <li>план розеток и выключателей</li>
                <li>план освещения</li>
                <li>план групп включений</li>
                <li>план теплых полов</li>
                <li>план потолков</li>
                <li>План спецификации дверей</li>
                <li>план отделки помещений</li>
                <li>развертки по помещениям</li>
                <li>Спецификация отделочных материалов</li>
              </ul>
            </ul>
          </div>
          <div className="price-bottom">
            <div className="price">4900 руб. / кв. м</div>
            <Link to="/contact"><Button text="Заказать" /></Link>
          </div>
        </div>
        <div className="prices-page_plan">
          <div className="prices-top">
            <h2>PRO</h2>
            <h3>Состав проекта:</h3>
            <ul>
              <li className='stage'>1 этап</li>
              <ul>
                <li>обмерный план</li>
                <li>планировочное решение</li>
                <li>план демонтажа</li>
                <li>план монтажа</li>
                <li>план сантехники</li>
              </ul>
              <li className='stage'>2 этап</li>
              <ul>
                <li>3D визуализация</li>
              </ul>
              <li className='stage'>3 этап</li>
              <ul>
                <li>план розеток и выключателей</li>
                <li>план освещения</li>
                <li>план групп включений</li>
                <li>план теплых полов</li>
                <li>план потолков</li>
                <li>план спецификации дверей</li>
                <li>развертки по помещениям</li>
                <li>спецификация отделочных материалов</li>
                <li>комплектация объекта</li>
              </ul>
            </ul>
          </div>
          <div className="price-bottom">
            <div className="price">6900 руб. / кв. м</div>
            <Link to="/contact"><Button text="Заказать" /></Link>
          </div>
        </div>
      </div>
    </div>
  )
}
