import React, { useEffect, useState } from 'react'
import { HeroBlock } from '../components/HeroBlock'
import { Card } from '../components/Card'
import { Button } from '../components/Button'
import { ContactForm } from '../components/ContactForm'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { supabase } from '../client'
import { Loading } from '../components/Loading'

export const Projects = ({ data, setModal }) => {
  const [projects, setProjects] = useState(undefined);
  const [filteredProjects, setFilteredProjects] = useState()
  const [filter, setFilter] = useState()

  async function getProjects() {
    let { data: projects, error } = await supabase
      .from('projects')
      .select('*')
    setProjects(projects)
  }

  useEffect(() => {
    getProjects()
  }, [])

  function filterProjects(how) {
    if (how !== "everything") {
      setFilter(how)
      const filtered = undefined;
      setFilteredProjects(projects.filter(item => item.type == how))
    }
    if (how == "everything") {
      setFilteredProjects(undefined)
      setFilter(undefined)
    }
  }

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 934px)'
  })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 933px)' })

  return (
    <div className="page">
      <HeroBlock
        title="Проекты"
        desc="Мы помогаем нашим клиентам
        воплотить их мечты о комфорте и уюте
        в индивидуальном стиле"
        bg="/images/hero_projects2.jpg"
        btn={<Link to="/contact"><Button text="Получить расчет" /></Link>}
        thread="проекты" />
      <section className="projects-block">
        <div className="site-container">
          {isDesktopOrLaptop && <div className="projects-block_selector">
            <button onClick={() => filterProjects("everything")} className={filter == undefined && 'selected'}>Все проекты</button>
            <button onClick={() => filterProjects("Жилые пространства")} className={filter == 'Жилые пространства' && 'selected'}>Жилые пространства</button>
            <button onClick={() => filterProjects("Общественные пространства")} className={filter == 'Общественные пространства' && 'selected'}>Общественные пространства</button>
            <button onClick={() => filterProjects("Частные дома")} className={filter == 'Частные дома' && 'selected'}>Частные дома</button>
            <button onClick={() => filterProjects("Outdoor")} className={filter == 'Outdoor' && 'selected'}>Outdoor</button>
            <button onClick={() => filterProjects("Рестораны")} className={filter == 'Рестораны' && 'selected'}>Рестораны</button>
          </div>}
          {isTabletOrMobile && <div className="projects-block_selector__mobile">
            <div className="selector-header">Показать:</div>
            <select onChange={(e) => filterProjects(e.target.value)}>
              <option value="everything" className='selected'>Все проекты</option>
              <option value="Жилые пространства">Жилые пространства</option>
              <option value="Общественные пространства">Общественные пространства</option>
              <option value="Частные дома">Частные дома</option>
              <option value="Outdoor">Outdoor</option>
              <option value="Рестораны">Рестораны</option>
            </select>
          </div>}
          {filteredProjects !== undefined ? <>
            {filteredProjects.length==0 && <h4 className='projects-other-projects'>{`Проектов с фильтром "${filter}" не найдено`}</h4>}
            <div className="projects-container">
              {filteredProjects.map((proj, index) => (
                <Card title={proj.title} key={index} imgsrc={proj.main_img} link={"/project/" + proj.url} />
              ))}
            </div>
            {<h4 className='projects-other-projects'>Другие проекты</h4>}
          </> : ""}
          {projects !== undefined ? <div className="projects-container">
            {projects.filter(item => item.type !== filter).map((proj, index) => (
              <Card title={proj.title} key={index} imgsrc={proj.main_img} link={"/project/" + proj.url} />
            ))}
          </div> : <Loading />}
        </div>
      </section>
    </div>
  )
}
