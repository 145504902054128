import React from 'react'
import { useMediaQuery } from 'react-responsive'

export const ServiceCard = (props) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 430px)' })

  if (!isTabletOrMobile) {
    return (
      <div className="service-card">
        <img draggable="false" src={"/icons/" + props.icon} alt="" />
        <div className="service-card_text">
          <span>{props.title}</span>
          <p>{props.desc}</p>
        </div>
      </div>
    )
  }
  if (isTabletOrMobile) {
    return (
      <div className="service-card">
        <div className="service-card_text">
          <div className="service-card_mobile-header">
            <img draggable="false" src={"/icons/" + props.icon} alt="" />
            <span>{props.title}</span>
          </div>
          <p>{props.desc}</p>
        </div>
      </div>
    )
  }
}
