import React from 'react'

export const Button = (props) => {
  if(props.isLoading == 'false' || props.isLoading == undefined){
    return (
      <button className={'btn ' + props.type} onClick={props.onClick}>
        {props.text}
      </button>
    )
  } else {
    return (
      <button className={'btn ' + props.type} disabled>
        <div className='loading-label'><img src="/icons/loader.svg" alt="" /></div>
      </button>
    )
  }
}
