import React, { useEffect, useState } from 'react'
import { HeroBlock } from '../components/HeroBlock'
import { Card } from '../components/Card'
import { Loading } from '../components/Loading';
import { supabase } from '../client'

export const Blog = () => {
  const [blogs, setBlogs] = useState(undefined);

  async function getBlogs(){
    let { data: blogs, error } = await supabase
    .from('blogs')
    .select('*')
    setBlogs(blogs)
  }

  useEffect(() => {
    getBlogs()
  }, [])

  return (
    <div className="page">
      <HeroBlock 
        title="Блог" 
        desc="Здесь мы рассказываем все новости о нас и о наших партнерах, последние тенденции и события" 
        bg="/images/hero_blog.jpg"
        thread="блог"/>
      <section className="projects-block">
        <div className="site-container">
          {blogs!==undefined ? <div className="projects-container">
            {blogs.map((art) => (
              <Card title={art.title} key={art.id} imgsrc={art.main_img} link={art.url}/>
            ))}
          </div> : <Loading/>}
        </div>
      </section>
    </div>
  )
}
