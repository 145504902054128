import React, { useState } from 'react'
import { HeroBlock } from '../components/HeroBlock'
import { ContactForm } from '../components/ContactForm'

export const Contact = () => {

  return (
    <div className='page'>
      <HeroBlock
        title="Контакты"
        desc="Отправьте нам запрос или предварительную информацию о ваших задачах, и мы свяжемся с вами в ближайшее время"
        bg="/images/hero_contact.jpg" />
      <div className="site-container contact-page">
        <ContactForm/>
        <div className="contact-page_addition">
          <a href="tel:+79778492010">{"+7 (977) 849 20 10"}</a>
          <a className='mail'>info@ecoartdesign.ru</a>
        </div>
      </div>
    </div>
  )
}
