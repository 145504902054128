import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { Projects } from './pages/Projects';
import { Blog } from './pages/Blog';
import { NotFound } from './pages/NotFound';
import { Route, Routes, useLocation } from "react-router-dom";
import { BlogPage } from './pages/BlogPage';
import { useEffect, useState } from 'react';
import { Services } from './pages/Services';
import { About } from './pages/About';
import { Prices } from './pages/Prices';
import { Contact } from './pages/Contact';
import { supabase } from './client'

function App() {
  

  const location = useLocation();
  const isNotFound = location.pathname === '*';

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path='/' exact element={<Projects/>} />
        <Route path='/project/:projectName' element={<BlogPage type="project"/>} />
        <Route path='/blog/:blogName' element={<BlogPage type="blog"/>} />
        <Route path='/blog' element={<Blog/>} />
        <Route path='/services' element={<Services/>} />
        <Route path='/prices' element={<Prices/>} />
        <Route path='/about' element={<About/>} />
        <Route path='/contact' element={<Contact/>} />
        <Route path='*' element={<NotFound/>} />
      </Routes>

      {!isNotFound && <Footer />}
    </div>
  );
}

export default App;
