import React from 'react'
import { HeroBlock } from '../components/HeroBlock'
import { Link } from 'react-router-dom'

export const About = () => {
  return (
    <div className="page">
      <HeroBlock 
        title="О нас" 
        desc="Дом - это состояние души. Узнайте больше о нашей компании"
        bg="/images/hero_about.jpg"/>
      <div className="site-container mr-top">
        <div className="about-us-block">
          <p>Приветствую Вас!<br/><br/>
          Меня зовут Ирина Соловьева.<br/><br/>
          Более 20 лет назад начала работать в строительной компании: занималась разработкой интерьеров и их эксплуатацией. Тогда я и поняла, что дизайн - это в первую очередь про удобство и эргономичность. Сейчас я являюсь руководителем студии дизайна интерьеров ЭКО АРТ, вместе с командой мы придерживаемся именно этой идеологии, взвешенно подходим к вопросу подбора материалов, текстур и архитектурных решений.</p>
          <img src="/images/about-us.jpg" alt="" />
          <p className="about-us_quote">Я приглашаю Вас вместе со мной в мир приятного путешествия экологичного дизайна интерьеров!</p>
          <Link className='images-cards_back' to="/">{"< Вернуться"}</Link>
        </div>
      </div>
    </div>
  )
}
