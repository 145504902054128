import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'

export const Header = () => {
  const [opened, setOpened] = useState(false)
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 934px)'
  })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 933px)' })

  if (opened) {
    document.getElementsByTagName("body")[0].style.overflow = "hidden";
  } else {
    document.getElementsByTagName("body")[0].style.overflow = "auto";
  }

  if (isDesktopOrLaptop) {
    return (
      <header>
        <div className="header-main">
          <div className="site-container header-flex">
            <Link to="/"><img className='header-logo' src="/images/logo_horizontal.svg" draggable="false" alt="Эко арт" /></Link>
            <div className="header-contacts">
              <a href="https://t.me/eco_art_design" className='header-icon'>
                <img src="/icons/telegram.svg" draggable='false' alt="Telegram" />
              </a>
              <a href="https://www.instagram.com/eco_art_design?igsh=NnBsMjF2Nmo5eTVx&utm_source=qr" className='header-icon'>
                <img src="/icons/instagram.svg" draggable='false' alt="Instagram" />
              </a>
              <a href="https://wa.me/message/OBQB5EDMFW4RJ1?src=qr" className='header-icon'>
                <img src="/icons/whatsapp.svg" draggable='false' alt="Whatsapp" />
              </a>
              <a href="tel:+79778492010" className='header-phone'>+7 977 849 20 10</a>
              <Link to="/contact" className='header-icon'>
                <img src="/icons/mail.svg" draggable='false' alt="Email" />
              </Link>
            </div>
          </div>
        </div>
        <div className="header-menu">
          <div className="site-container">
            <ul>
              <li><Link to="/about">О нас</Link></li>
              <li><Link to="/">Проекты</Link></li>
              <li><Link to="/services">Услуги</Link></li>
              <li><Link to="/prices">Тарифы</Link></li>
              {/* <li><Link to="/brands">Бренды</Link></li> */}
              <li><Link to="/blog">Блог</Link></li>
              <li><Link to="/contact">Контакты</Link></li>
            </ul>
            <a href="/files/Portfolio.pdf" className='header_pdf' download="Портфолио_Эко_арт"><img src="/icons/file.svg" alt="" /> Презентация</a>
          </div>
        </div>
      </header>
    )
  }
  if (isTabletOrMobile) {
    return (
      <header>
        <div className="header-main">
          <div className="site-container header-flex">
            <Link to="/"><img className='header-logo' src="/images/logo_horizontal.svg" draggable="false" alt="Эко арт" /></Link>
            <div className="header-contacts">
              <button className='header-mobile-menu-btn header-icon' onClick={() => setOpened(!opened)}>
                <img src="/icons/menu.svg" draggable='false' alt="Menu" />
              </button>
            </div>
          </div>
        </div>
        <div className="header-mobile-menu" opened={opened.toString()}>
          <div className="site-container">
            <nav>
              <Link onClick={() => opened && setOpened(false)} to="/about">О нас</Link>
              <Link onClick={() => opened && setOpened(false)} to="/">Проекты</Link>
              <Link onClick={() => opened && setOpened(false)} to="/services">Услуги</Link>
              <Link onClick={() => opened && setOpened(false)} to="/prices">Тарифы</Link>
              <Link onClick={() => opened && setOpened(false)} to="/blog">Блог</Link>
              <Link onClick={() => opened && setOpened(false)} to="/contact">Контакт</Link>
            </nav>
            <div className="header-mobile-bottom">
              <div className="group1">
                <div className="header-contacts">
                  <a href="https://t.me/eco_art_design" className='header-icon'>
                    <img src="/icons/telegram.svg" draggable='false' alt="Telegram" />
                  </a>
                  <a href="https://www.instagram.com/eco_art_design?igsh=NnBsMjF2Nmo5eTVx&utm_source=qr" className='header-icon'>
                    <img src="/icons/instagram.svg" draggable='false' alt="Instagram" />
                  </a>
                  <a href="https://wa.me/message/OBQB5EDMFW4RJ1?src=qr" className='header-icon'>
                    <img src="/icons/whatsapp.svg" draggable='false' alt="Whatsapp" />
                  </a>
                  <a href="mailto:info@ecoartdesign.ru" className='header-icon'>
                    <img src="/icons/mail.svg" draggable='false' alt="Email" />
                  </a>
                </div>
              </div>
              <div className="group2">
                <a href="tel:+79778492010" className='header-phone'>+7 977 849 20 10</a>
                <a href="/files/Portfolio.pdf" className='header_pdf' download="Портфолио_Эко_арт"><img src="/icons/file.svg" alt="" /> Презентация</a>
              </div>
            </div>
          </div>
        </div>
        <div className="header-dim" show={opened.toString()} onClick={() => opened && setOpened(false)}/>
      </header>
    )
  }
}
